/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 * 
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * museumsguiden.no (monthly pageviews: <50K)
 *
 */



@font-face {
  font-family: 'AkkuratLLWeb';
  font-weight: 700;
  src: local('AkkuratLLWeb'),
    url(./assets/fonts/AkkuratLLWeb-Bold.woff) format("woff");
}

@font-face {
  font-family: 'AkkuratLLWeb';
  font-weight: 400;
  src: local('AkkuratLLWeb'),
    url(./assets/fonts/AkkuratLLWeb-Regular.woff) format("woff");
}



:root{
  --roboto: 'Roboto', sans-serif;
  --akkurat: AkkuratLLWeb, sans-serif; 

  /*COLORS*/
  --Museumssvart: #1C2225;
  --brand-color-for-type: #36312E;
  
  --blaze100: #FEDFCE; 
  --blaze500: #FB5F0A;
  --blaze600: #C94C08;
  --gray400: #CBD5E0;
  --gray500: #71717A;
  --gray600: #5A5A62;
  --Museumsbakgrunn: #EAE9E6;/*gammel farge #F1F0EE; */
  /*overflow: auto;*/
  /*overflow-y: scroll;*/
}

body {
  margin: 0;
  font-family: var(--akkurat), sans-serif ; /*-apple-system, BlinkMacSystemFont, 'AkkBd-Pro', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--Museumsbakgrunn);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div#root {
  /*overflow: auto;*/ /* TEST */
  /*overflow-y: scroll;*/ /* TEST */
  
  display: flex;
  flex: 1;
}
